const formatDate = (date) => {
  return date.toISOString().split('T')[0]; // YYYY-MM-DD format
};

export function queryParams(route, args) {
  let _args = { ...args };
  let url = route(_args);

  const today = new Date();

  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  if (!_args?.start_date) {
    _args.start_date = formatDate(sevenDaysAgo);
  }

  if (!_args?.end_date) {
    _args.end_date = formatDate(today);
  }

  url += `?${new URLSearchParams(_args).toString()}`;

  return url;
}
