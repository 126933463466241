import React, { useEffect } from 'react';
import ChatRoomForm from './bottom-area/chat-room-form-v2';
import ChatMessages from './upper-area/chat-messages';
import useSetChatSession from '../../hooks/chat-room/useSetChatSession';
import { useChatRoom } from '../../hooks/chat-room';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../../lib/redux/chat/slice';
import usePreviewMode from '../../hooks/iframe/usePreviewMode';
import Welcome from './upper-area/welcome';
import useEmbedMode from '../../hooks/iframe/useEmbedMode';

export default function ChatRoom() {
  const chat = useSelector((state) => state.chat);
  const auth = useSelector((state) => state.auth.data);
  const { createChatSession } = useSetChatSession();
  const { sidebar } = useSelector((state) => state.navigation.data);
  const embedPreview = usePreviewMode();
  const embed = useEmbedMode();
  const dispatch = useDispatch();
  const {
    generating,
    streaming,
    handleUserPromptFormSubmit,
    handlePromptChange,
    handleStopRespondingBtnClick,
  } = useChatRoom();

  useEffect(() => {
    dispatch(chatActions.generatingUpdated(generating));
  }, [generating, dispatch]);

  useEffect(() => {
    dispatch(chatActions.streamingUpdated(streaming));
  }, [streaming, dispatch]);

  useEffect(() => {
    if (!window.creatingSession && !embedPreview) {
      window.creatingSession = true;
      createChatSession();
    }
  }, [chat.sequence, auth.user]);

  const containerStyle = { marginRight: '20px' };
  if (embed) {
    containerStyle.marginLeft = '10px';
    containerStyle.marginRight = '10px';
    containerStyle.paddingLeft = 'unset !important';
  }

  return (
    <div
      className={`body-right-side light-grey-bg-dark-mode no-padding`}
      style={containerStyle}
    >
      {chat?.messages?.length > 0 ? (
        <ChatMessages generating={generating} streaming={streaming} />
      ) : (
        <div className="upper-area">
          <Welcome />
        </div>
      )}

      <ChatRoomForm
        handlePromptChange={handlePromptChange}
        handleUserPromptFormSubmit={handleUserPromptFormSubmit}
        handleStopRespondingBtnClick={handleStopRespondingBtnClick}
        generating={generating}
        streaming={streaming}
      />
    </div>
  );
}
