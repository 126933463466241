import { useLoaderData, Outlet, useParams } from 'react-router-dom';
import { createContext, useContext, useEffect } from 'react';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { authActions } from '../../lib/redux/auth/slice';
import { useDispatch, useSelector } from 'react-redux';

const PlatformContext = createContext();

export const usePlatformData = () => useContext(PlatformContext);

export default function PlatformLayout() {
  const { mentorSettings } = useLoaderData();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.data);
  const urlParams = new URLSearchParams(window.location.search);
  const pathParams = useParams();

  useEffect(() => {
    if (mentorSettings) {
      const mentorData = {
        name: mentorSettings.mentor,
        slug: mentorSettings.mentor_slug,
        unique_id: mentorSettings.mentor_unique_id,
        suggested_prompts: mentorSettings?.suggested_prompts,
        settings: mentorSettings,
        proactive_prompt: mentorSettings?.proactive_prompt,
        metadata: mentorSettings?.metadata,
      };

      dispatch(mentorActions.currentMentorUpdated(mentorData));

      const userData = localStorage.getItem('userData');
      if (!userData) {
        dispatch(
          authActions.authUpdated({
            ...auth,
            tenant: { key: pathParams.platform },
          })
        );
      }

      if (urlParams.get('internalPreview') === 'true') {
        localStorage.setItem('previewMentorData', JSON.stringify(mentorData));
      }
    }
  }, [mentorSettings]);

  return (
    <PlatformContext.Provider value={mentorSettings}>
      <Outlet />
    </PlatformContext.Provider>
  );
}
